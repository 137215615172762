import {errorFlag} from '../../actions/ErrorActions'
import {APP_CONSTANT, FEATURE_UI_NAME} from '../../../components/helperComponents/Constants'
import {
  processAmazonAlexaPrivacies,
  processCarbPrivacies,
  processMobileAppCookiePrivacies,
  processPersonalizationPrivacies,
  processPreferredDealerprivacies,
  processSucPrivacies,
  processVehicleHealthToDealerprivacies,
} from './PrivacyApplicationDataProcessor'
import {
  setManageDataWithFord,
  setManageDataWithThirdParties,
  setMobileAppCookies,
  setUnlinkThirdPartyAccounts,
} from '../../actions/TabActions'
import i18n from 'i18next'
import {BANNER_FEATURE, BANNER_TYPE} from '../../../components/error/ErrorBanner'
import {getBrandedTranslation} from '../../../components/helperComponents/contentUtils'

export const privacyNormalizerMiddleware = (store) => (next) => (action) => {
  next(action)
  switch (action.type) {
    case '[PRIVACY_DATA] NORMALIZE':
      try {
        const privacyData = action.payload.data.privaciesV2
        const garageVins = store.getState().vins.garageVins
        const authorizedVins = store.getState().vins.authorizedVins
        const userState = store.getState().userState

        const thirdPartyDataMap = new Map()
        const macDataMap = new Map()
        const fordDataMap = new Map()
        const unlinkThirdPartyDataMap = new Map()
        Object.keys(privacyData.privacyCategories)
          .filter((privacyCategory) => privacyData.privacyCategories[privacyCategory].isLaunched === 'true')
          .forEach((privacyCategory) => {
            const privacyCategoryType = privacyData.privacyCategories[privacyCategory].type[0]
            if (privacyCategoryType === 'PSN') {
              processPersonalizationPrivacies(privacyData, privacyCategory, authorizedVins, fordDataMap)
            }
            if (userState === 'CA' && privacyCategoryType === 'ECO') {
              processCarbPrivacies(privacyData, privacyCategory, authorizedVins, thirdPartyDataMap)
            }
            if (
              privacyCategoryType === 'DA' &&
              privacyData.privacyCategories[privacyCategory].uiName === FEATURE_UI_NAME.amazonAlexa &&
              privacyData.privacyCategories[privacyCategory].privacies.some((privacy) =>
                privacy.privacyPreferences.some(
                  (preference) =>
                    preference.statusCode === 'Y' &&
                    authorizedVinsContainsPrivacyDeviceValue(preference, authorizedVins),
                ),
              )
            ) {
              processAmazonAlexaPrivacies(
                privacyData,
                privacyCategory,
                authorizedVins,
                unlinkThirdPartyDataMap,
              )
            }
            if (privacyCategoryType === 'SUC') {
              processSucPrivacies(privacyData, privacyCategory, authorizedVins, garageVins, fordDataMap)
            }
            if (privacyCategoryType === 'MAC') {
              processMobileAppCookiePrivacies(privacyData, privacyCategory, authorizedVins, macDataMap)
            }
            if (privacyCategoryType === 'VHD') {
              if (
                privacyData.privacyCategories[privacyCategory].uiName ===
                FEATURE_UI_NAME.vehicleHealthToDealers
              ) {
                processVehicleHealthToDealerprivacies(
                  privacyData,
                  privacyCategory,
                  authorizedVins,
                  thirdPartyDataMap,
                )
              } else if (
                privacyData.privacyCategories[privacyCategory].uiName === FEATURE_UI_NAME.preferredDealer && privacyData.privacyCategories[privacyCategory].brand.includes(APP_CONSTANT.CONFIG.BRAND_CODE)
              ) {
                processPreferredDealerprivacies(
                  privacyData,
                  privacyCategory,
                  authorizedVins,
                  thirdPartyDataMap,
                )
              }
            }
          })

        const sortByAppName = (app1, app2) => {
          const key1 = i18n.t(app1.uiName)
          const key2 = i18n.t(app2.uiName)
          return key1.localeCompare(key2)
        }

        thirdPartyDataMap.forEach((value, key) => {
          thirdPartyDataMap.get(key).sort(sortByAppName)
        })

        fordDataMap.forEach((value, key) => {
          fordDataMap.get(key).sort(sortByAppName)
        })

        store.dispatch(setManageDataWithThirdParties(thirdPartyDataMap))
        store.dispatch(setManageDataWithFord(fordDataMap))
        store.dispatch(setMobileAppCookies(macDataMap))
        store.dispatch(setUnlinkThirdPartyAccounts(unlinkThirdPartyDataMap))
      } catch (error) {
        catchPrivacyError(store, action, error)
      }
      break
  }

  function catchPrivacyError(error) {
    console.log(
      `Error while normalizing get Privacies data for source ${
        action.type
      } and error details: ${error.toString()}`,
    )

    store.dispatch(
      errorFlag({
        state: BANNER_TYPE.ERROR,
        errorStatus: true,
        message: APP_CONSTANT.CONFIG.IS_OWNER
          ? i18n.t('lbl_generic_error_web_part1')
          : getBrandedTranslation('lbl_generic_error'),
        feature: BANNER_FEATURE.GENERIC,
      }),
    )
  }

  function authorizedVinsContainsPrivacyDeviceValue(privacyPreference, authorizedVins) {
    return authorizedVins.map((vins) => vins.vin).includes(privacyPreference.devices[0]?.deviceValue)
  }
}
