import axios from 'axios'
import {API_REQUEST, apiError, apiSuccess} from '../../actions/ApiActions'

export const apiMiddleware =
  ({dispatch}) =>
    (next) =>
      (action) => {
        next(action)

        if (action.type.includes(API_REQUEST)) {
          const body = JSON.stringify(action.payload)
          const {url, method, headers, feature, uiName, responseType} = action.meta
          const request = () =>
            method === 'GET' ? {method, url, headers, responseType, withCredentials: true} : {method, url, headers, data: body, withCredentials: true}
          axios(request())
            .then((response) => {
              dispatch(apiSuccess({response, feature, uiName}))
            })
            .catch((error) => {
              dispatch(apiError({error, feature, uiName}))
            })
        }
      }
