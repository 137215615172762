
export const bffAuthorizeAPI = () => ({
  type: `BFF_AUTHORIZE_API`,
  payload: 'BFF Authorize API',
})

export const bffAggregatorAPI = () => ({
  type: `BFF_AGGREGATOR_API`,
  payload: 'BFF Aggregator API',
})

export const bffFordPassAggregatorAPI = () => ({
  type: `BFF_FORDPASS_AGGREGATOR_API`,
  payload: 'BFF FordPass Aggregator API',
})